import React from 'react';

const Cta = () => {
    return (
        <section className="bg-neutral" id="connect">
            <div className="inner">
                <h2>Let's Connect.</h2>
                <p className="underline"><a href="mailto:rachelbatesnj@gmail.com" target="_blank" rel="noreferrer">Send me an email with your ideas</a></p>
            </div>
        </section>
    )
};

export default Cta;
