import React from "react";

export const experiences = [
    {
        company: "Making Space",
        position: "Senior Full Stack Software Engineer",
        dates: "October 2023 - Present",
        location: "Remote",
        logo: "images/logos/logo-makingspace.jpeg",
        url: "https://making-space.com/",
        description: (
            <>
                <p><i>Seed Stage startup in the disability hiring and education industry.
                     Building with approximately 3 members on the Product/Engineering team. 
                </i></p>
                <p>* Create an automated system for translation of our entire site into multiple languages</p>
                <p>* Design and build an accessible slide deck player experience using AWS Bedrock Sonnet AI for semantic screen reader transcripts</p>
                <p>* Create a multi-form course builder wizard that allows partners to construct educational materials for our users.</p>
            </>
        ),
    },
    {
        company: "AdRoll, a division on NextRoll",
        position: "Full Stack Software Engineer",
        dates: "March 2022 - July 2023 (RIF)",
        location: "Remote",
        logo: "images/logos/logo-nextroll.jpeg",
        url: "https://www.nextroll.com/",
        description: (
            <>
                <p>* Act as tech lead for reports redesign project</p>
                <p>* Create more email campaign insights for customers by using <strong>HighCharts, React, Redux, and Go</strong></p>
                <p>* Champion development of new geographical report by working across multiple microservices for data consolidation, exporting of data, and scheduling scripts</p>
            </>
        ),
    },
    {
        company: "iRobot",
        position: "User Interface Developer",
        dates: "September 2021 - March 2022",
        location: "Remote",
        logo: "images/logos/logo-irobot.jpeg",
        url: "https://www.irobot.com/",
        description: ( 
            <>
                <p>* Personalize customers’ website user experience by developing customizable components in Salesforce Interaction Studio</p>
                <p>* Coalesce iRobot’s web brand refresh with a new navigation header and footer by using <strong>SCSS and Javascript</strong> animation effects</p>
                <p>* Spearhead effort to re-organize component system legacy code, resulting in a faster load speed for all pages with Interaction Studio components</p>
            </>
        ),
    }, 
    {
        company: "Hackbright Academy",
        position: "Software Engineering Fellow",
        dates: "April 2021 - July 2021",
        location: "Remote",
        logo: "images/logos/logo-hackbright.jpeg",
        url: "https://hackbrightacademy.com/",
        description: (
            <>
                <p>* This selective program focuses on training software engineering with current technologies and development strategies.</p>
                <p>* During the first half of this cohort, we spend hours in instruction and in group lab, pair programming and solving challenges together.</p>
                <p>* The second half is spent developing a full- stack web application, meeting daily for scrum meetings and further lectures.</p>
                <p><strong>Skills: Python, Javascript, Flask, React, data structures, algorithims, mySQL, postgres, REST APIs, CSS</strong></p>
            </>
        )
    },  
    {
        company: "2U",
        position: "Web Developer",
        dates: "December 2019 - April 2021",
        location: "Brooklyn, New York",
        logo: "images/logos/logo-2u.jpeg",
        url: "https://2u.com/",
        description: (
            <>
                <p>* Develop and prioritize ongoing web updates on organic and paid web pages with partner programs using <strong>CSS (Flexbox, modified Bootstrap) and HTML</strong> to keep university websites current.</p>
                <p>* Advise and coordinate with stakeholders on web accessibility (WCAG 2.0), user experience, marketing team priorities, and website best practices to ensure quality delivery on every page.</p>
            </>
        )
    },
    {
        company: "BHLDN / Anthropologie Weddings",
        position: "Web Developer",
        dates: "August 2018 - November 2019",
        location: "Philadelphia, Pennsylvania",
        logo: "images/logos/logo-bhldn.jpeg",
        url: "https://www.bhldn.com/",
        description: (
            <>
                <p>* Improve homepage load speed almost 1 second faster by implementing lazy-load and making recommendations on constraining the amount and type of page content</p>
                <p>* Generate a wealth of e-commerce web content by developing creative marketing materials using <strong>HTML, CSS, and JavaScript</strong></p>
                <p>* Perfect BHLDN’s resource lookbooks’ visual experience by interacting closely with designers during development, resulting in cross-browser compatible, mobile responsive, and user-friendly web pages</p>
            </>
        )
    },
]