import React from 'react'
import { experiences } from './content'

const Experience = () => {
    return (
        <section id="experience">
            <div className="inner-lg">
                <h2>Experience</h2>
                    <div className="md:flex content-between flex-wrap">
                        {experiences.map((item) =>
                            <div className="card basis-1/3 mx-4 my-5 relative">
                                <div className="flex justify-between">
                                    <h3 className="mb-0 pb-0">{item.position}</h3>
                                    <a href={item.url} target="_blank" rel="noreferrer" className="logo-link">
                                        <img className="w-6" src={item.logo} alt={item.company} />
                                    </a>
                                </div>
                                <h4 className="mb-0">{item.company}</h4>
                                <p className="mb-2"><strong>{item.dates} | {item.location}</strong></p>
                                <p>{item.description}</p>
                            </div>
                        )}
                    </div>
            </div>
      </section>
    )
}

export default Experience;
