// import logo from './logo.svg';
import React from 'react';
import Experience from './components/Experience.js';
// import Nav from './components/Nav.js'
import Hero from './components/Hero.js'
import About from './components/About.js'
// import Projects from './components/Projects.js'
import Skills from './components/Skills.js'
import Cta from './components/Cta.js'

function App() {
  return (
    <>
      {/* <Nav /> */}
      <Hero />
      <About />
      {/* <Projects /> */}
      <Experience />
      <Skills />
      <Cta />
    </>
    )
}

export default App;