import React from 'react';
const Skills = () => {
  return (
    <section id="tech-stack">
      <div className="inner-lg text-center">
        <h2>Tools &amp; Technologies</h2>
        <p>Here are a few technologies and frameworks I've enjoyed working with:</p>
        <ul className="list-disc flex justify-center text-left">
          <div className="p-6">
            <li>React.JS</li>
            <li>SASS/SCSS, Tailwind</li>
            <li>Redux & RTK Query</li>
            <li>Next.JS</li>
            <li>Vanilla Javascript and DOM manipulation</li>
          </div>
          <div className="p-6">
            <li>Python Flask</li>
            <li>Ruby on Rails</li>
            <li>AWS Suite (i.e. S3, Lambda, Cloudfront)</li>
            <li>Heroku</li>
            <li>SQL</li>
          </div>
        </ul>              
      </div>
    </section>
  )
};

export default Skills;
